import { configPrepare } from "@/helpers/vue-good-table"
import { i18n } from "@/config"
import { isSharedInventoryEnabled } from "@/helpers/organization"
import { translateOtaName } from "@/helpers/common"

const defaultTableClasses = {
  tdClass: "logs-table-td",
  thClass: "logs-table-th"
}

export const i18nPrefix = "logs.table.columns"

const buildFields = () => [
  "created_at",
  {
    field: "type",
    formatFn: type => i18n.t(`logs.user_action_log.types.${type}`)
  },
  {
    field: "inventory_group_name",
    formatFn: inventory_group_name => inventory_group_name || "—",
    hidden: !isSharedInventoryEnabled()
  },
  {
    field: "shop_name",
    formatFn: shop_name => shop_name || "—"
  },
  {
    field: "car_class_names",
    formatFn: car_class_names => car_class_names || "—"
  },
  {
    field: "initiator_name",
    formatFn: ({ title, translate }) => (translate ? translateOtaName(title) : title)
  },
  "text"
]

export const columns = () =>
  configPrepare(buildFields(), { i18nPrefix, defaults: defaultTableClasses, backendSort: false })
